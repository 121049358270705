
import { utcToZonedTime } from 'date-fns-tz';
import useLocale from '../useLocale';

export interface StartDateProps {
  date: string;
}

/**
 * @param date UTC date time.
 */
const StartDate = ({ date }: StartDateProps) => {
  const { formatDate, parseDate, timezone } = useLocale();

  const startDate = utcToZonedTime(parseDate(date, { timezone: 'UTC' }), timezone);

  const format = startDate.getHours() + startDate.getMinutes() + startDate.getSeconds() > 0
    ? 'display_time_slot_date_time' : 'display_time_slot_date';

  return (
    <>
      {formatDate(startDate, { format, timezone: null })}
    </>
  );
};

export default StartDate;
